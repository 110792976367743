<template>
  <div class="page">
    <van-sticky>
      <van-tabs v-model="active">
        <van-tab title="设备"></van-tab>
        <van-tab title="骑手"></van-tab>
        <van-tab title="用户"></van-tab>
        <van-tab title="任务"></van-tab>
      </van-tabs>
    </van-sticky>
    <cabinet v-if="active === 0"/>
    <rider v-if="active === 1"/>
    <user v-if="active === 2"/>
    <request-list v-if="active === 3"/>
  </div>
</template>

<script>
import cabinet from "@/pages/mock/cabinet";
import rider from "@/pages/mock/rider";
import user from "@/pages/mock/user";
import requestList from "@/pages/mock/requestList";

export default {
  name: "index",
  components: {cabinet, rider, user, requestList},
  data() {
    return {
      active: 0
    }
  }
}
</script>

<style scoped>
/deep/ .van-pull-refresh__track {
  min-height: calc(100vh - 50px);
}
/deep/ .van-list {
  padding-bottom: 100px;
}

/deep/ .fab {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 16px;
  bottom: 66px;
  z-index: 999;
}
/deep/ .fab:active {
  background-color: brown;
}
</style>
